import Helmet from 'react-helmet'
import React from 'react'

const Twitter = ({ handle, title, description, image }) => (
  <Helmet>
    <meta name="twitter:card" content="summary_large_image" />
    {handle && <meta name="twitter:creator" content={handle} />}
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    {image && <meta name="twitter:image" content={image} />}
  </Helmet>
)

export default Twitter
