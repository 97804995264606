import { css } from 'styled-components'

const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
}

const Media = Object.entries(sizes).reduce(
  (acc, [label, maxWidth]) => ({
    ...acc,
    [label]: (...args) => css`
      @media (max-width: ${maxWidth / 16}em) {
        ${css(...args)}
      }
    `,
  }),
  {}
)

Media.largeDesktop = (...args) => css`
  @media (min-width: ${1200 / 16}em) {
    ${css(...args)}
  }
`

export default Media
