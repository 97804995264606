import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import Media from '../Media'
import { FancyLinks } from '../RootStyle'

const TagContainer = styled.div`
  :not(:last-of-type) {
    margin-right: 0.6rem;
  }
`

const TagLink = styled(Link)`
  font-family: ${({ theme: { tagsFont, bodyFont } }) => tagsFont || bodyFont};
  /* font-weight: ${({ theme }) => theme.header.fontWeight}; */
  text-decoration: none;
  border-bottom: 1px dotted;

  :hover {
    text-decoration: none;
    border-bottom: 2px solid;
  }
`

const Tag = ({ tag }) => (
  <TagContainer>
    <TagLink to={`/tags/${tag}`}>{`#${tag}`}</TagLink>
  </TagContainer>
)

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${Media.phone`
    width: 100%;
    justify-content: flex-start;
  `}
  ${FancyLinks}
`

const Tags = ({ tags }) => (
  <TagsContainer>
    {tags.map(tag => (
      <Tag key={tag} tag={tag} />
    ))}
  </TagsContainer>
)

export default Tags
