import React from 'react'
import RootStyle from './RootStyle'
import Header from './Header'
import styled from 'styled-components'
import '../components/prism.css'
import { StaticQuery, graphql } from 'gatsby'

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        url
        title
        siteName
        punchline
      }
      pathPrefix
    }
  }
`

const Page = styled.div`
  overflow: auto;
  flex: 1;
  height: 100%;
  flex-flow: column;
`

const Contents = styled.main`
  margin: 0 auto;
  height: 100%;
`

const MainContainer = styled.div`
  display: flex;
  flex-flow: column;

  min-height: 100vh;
  height: 100vh;
`

const Layout = ({
  children,
  data: {
    site: {
      siteMetadata: { siteName, punchline },
    },
  },
  isHomePage,
  isPost,
}) => (
  <RootStyle>
    <MainContainer>
      <Header title={siteName} {...{ isHomePage, punchline }} />
      <Page {...{ isPost }}>
        <Contents>{children}</Contents>
      </Page>
    </MainContainer>
  </RootStyle>
)

const LayoutWithQuery = ({ children, isHomePage, isPost }) => (
  <StaticQuery
    query={query}
    render={data => <Layout {...{ data, children, isHomePage, isPost }} />}
  />
)

export default LayoutWithQuery
