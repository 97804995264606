import React from 'react'
import styled from 'styled-components'
import Tags from './blog/Tags'
import Media from './Media'

const PostMetadataContainer = styled.div`
  color: ${({ theme: { metadataColor } }) => metadataColor};
  display: flex;
  font-size: 90%;
  flex-wrap: wrap;
  line-height: normal;

  ${Media.phone`
    flex-flow: column; 
  `}
`

const PostDate = styled.div`
  margin-right: 2rem;
  /* font-weight: ${({ theme }) => theme.header.fontWeight}; */
  line-height: normal;
  font-family: ${({ theme }) => theme.headlineFont};
  ${Media.phone`
    line-height: 200%; 
  `}
`

const PostMetadata = ({ date, tags }) => (
  <PostMetadataContainer>
    <PostDate>{date}</PostDate>
    <Tags tags={tags} />
  </PostMetadataContainer>
)

export default PostMetadata
