import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import React from 'react'
import Media from './Media'

const HeaderStyle = styled.nav`
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);

  padding-top: 1rem;
  color: white;
  background: black;
  padding: 0.5rem 0.2rem 0.5rem 0.2rem;

  a {
    color: ${({ theme }) => theme.header.link.color};
    border-bottom: 1px dotted;

    :hover {
      color: ${({ theme }) => theme.header.link.color};
      border-bottom: 1px solid;
    }
  }
`
const Content = styled.div`
  display: flex;
  margin: auto;
  max-width: ${({ theme }) => theme.maxwidth};
  align-items: center;
`
export const Menu = styled.ul``

export const MenuItemContainer = styled.li`
  ${Media.tablet`
    font-size: 1rem;
  `}
  ${Media.phone`
    font-size: 0.9rem;
    padding-right: 0.3rem;
  `}
  list-style-type: none;
`
export const MenuItem = ({ to, children }) => (
  <MenuItemContainer>
    <Link to={to}>{children}</Link>
  </MenuItemContainer>
)

const titleStyle = css`
  text-align: center;
  margin: 0.5rem;
  display: contents;
  line-height: 1rem;

  a {
    font-family: ${({ theme }) => theme.titleFont};
    font-weight: ${({ theme }) => theme.header.fontWeight};
    font-size: 1.2rem;
    border-bottom: none;

    :hover {
      border-bottom: none;
    }
  }
  ${Media.phone`
    a {
      font-size: 1.1rem; 
    }
  `}
`
const TitleH1 = styled.h1`
  ${titleStyle}
`
const TitleSpan = styled.div`
  ${titleStyle}
`

function Title({ isHomePage, children }) {
  const Component = isHomePage ? TitleH1 : TitleSpan
  return (
    <Component>
      <Link to={'/'}>{children}</Link>
    </Component>
  )
}
const Left = styled.div`
  flex: 1;
`

const Right = styled.div`
  flex: 1;
  text-align: right;
`

const Center = styled.div`
  flex: 2;
  text-align: center;
`

const Header = ({ isHomePage, title, pages = [] }) => (
  <HeaderStyle>
    <Content>
      <Left />
      <Center>
        <Title {...{ isHomePage }}>{title}</Title>
      </Center>
      <Right>
        <Menu>
          <MenuItem to={'/about'}>About</MenuItem>
          {pages.map(
            ({
              node: {
                frontmatter: { title },
                fields: { slug },
              },
            }) => (
              <MenuItem key={slug} to={slug}>
                {title}
              </MenuItem>
            )
          )}
        </Menu>
      </Right>
    </Content>
  </HeaderStyle>
)

export default Header
