import React from 'react'
import md5 from 'md5'
import Twitter from './Twitter'
import OpenGraph from './OpenGraph'
import GeneralTags from './GeneralTags'
import LdJson from './LdJson'

const calculateGravatarUrl = gravatar =>
  `${'https://www.gravatar.com/avatar/'}${md5(gravatar.trim().toLowerCase(), {
    encoding: 'binary',
  })}?s=200`

const SEO = ({
  siteName,
  title,
  description,
  isPost,
  url,
  social: { gravatar, twitter } = {},
  image = gravatar && calculateGravatarUrl(gravatar),
  author,
  date,
}) => (
  <>
    <GeneralTags description={description} image={image} title={title} />
    <LdJson
      date={date}
      url={url}
      title={title}
      isPost={isPost}
      image={image}
      author={author}
    />
    <OpenGraph
      siteName={siteName}
      iPost={isPost}
      title={title}
      description={description}
      image={image}
      url={url}
    />
    <Twitter
      handle={twitter}
      title={title}
      description={description}
      image={image}
    />
  </>
)

export default SEO
