import Helmet from 'react-helmet'
import React from 'react'

const GeneralTags = ({ description, image, title, keywords }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    {image && <meta name="image" content={image} />}
    {keywords && <meta name="keywords" content={keywords} />}
    <html lang="en" />
  </Helmet>
)

export default GeneralTags
