import React from 'react'
import styled from 'styled-components'
import Divider from '../components/Divider'
import SocialIcons from '../components/SocialIcons'
import Link from 'gatsby-link'
import Media from './Media'
import { FancyLinks } from './RootStyle'
import { StaticQuery, graphql } from 'gatsby'

const FooterContainer = styled.footer`
  display: flex;
  flex-flow: column;
  margin: 10rem auto 0 auto;

  ${Media.phone`
    margin-top: 4rem;
  `}

  ${Media.tablet`
    margin-top: 6rem;
  `}

  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 75%;
    line-height: 1.4rem;
  }
  h2 {
    font-size: 110%;
    padding: 0.5rem 0;
  }
  h3 {
    font-size: 100%;
    padding: 0.5rem 0;
  }
  a {
    font-size: 75%;
  }
  li {
    line-height: 2rem;
    ${FancyLinks}
    a {
      padding: 0.2rem;
    }
  }
`

const FooterCards = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 1rem;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.maxwidth};
`
const FooterCard = styled.div`
  flex-basis: 15rem;
  padding-bottom: 1rem;
  padding-right: 0.75rem;

  p {
    text-align: left;
  }

  ${Media.phone`
    flex-shrink: 0;
    padding-right: 0;
  `}
`

const Disclaimer = styled(FooterCard)`
  flex-grow: 1;
  ${Media.phone`
    margin-top: 2rem;
    border-top: 1px solid #dbdbdb;
  `}
`

const Copyright = styled.div`
  font-size: 60%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: center;
`
const Navigation = ({ pages }) => (
  <FooterCard>
    <h2>Navigate</h2>
    <ul>
      {pages &&
        pages.map(({ node: { frontmatter: { title }, fields: { slug } } }) => (
          <li key={slug}>
            <Link to={slug}>{title}</Link>
          </li>
        ))}
      <li>
        <Link to={'/'}>Home</Link>
      </li>
      <li>
        <Link to={'/about'}>About me</Link>
      </li>
    </ul>
  </FooterCard>
)
const Social = ({ social }) => (
  <FooterCard>
    <h2>Find me on</h2>
    <SocialIcons size="28px" {...social} />
  </FooterCard>
)
const Footer = ({ copyright, pages, disclaimer: { html }, social }) => (
  <FooterContainer>
    <Divider />
    <FooterCards>
      <FooterCard>
        <FooterCard>
          <h2>I am Victor Garcia</h2>
          <p>Full-stack developer, software architect, cloud/aws</p>
        </FooterCard>
        <Social social={social} />
        <Navigation pages={pages} />
      </FooterCard>

      <Disclaimer dangerouslySetInnerHTML={{ __html: html }} />
    </FooterCards>
    <Divider />
    <Copyright>{copyright}</Copyright>
  </FooterContainer>
)

const query = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        url
        title
        siteName
        punchline
        copyright
        social {
          twitter
          gravatar
          gitlab
          github
          linkedIn
          stackOverflow
        }
      }
      pathPrefix
    }
    pages: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    disclaimer: markdownRemark(frontmatter: { title: { eq: "Disclaimer" } }) {
      fields {
        slug
      }
      html
    }
  }
`

const FooterWithQuery = () => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { copyright, social },
      },
      pages,
      disclaimer,
    }) => (
      <Footer
        pages={(pages && pages.edges) || []}
        copyright={copyright}
        disclaimer={disclaimer}
        social={social}
      />
    )}
  />
)

export default FooterWithQuery
