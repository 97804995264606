import base from './base'
import 'typeface-sonsie-one'
import 'typeface-biorhyme'
// import 'typeface-roboto'
import 'typeface-nunito'

const textColor = 'rgb(0,0,0,0.85)'

const Montserrat = {
  ...base,
  fontSize: '1.1rem',
  bodyFont: '"Nunito", sans-serif',
  titleFont: `'Sonsie One', sans-serif`, // good contrast fun,
  tagsFont: `'Nunito', sans-serif`,
  headlineFont: `'BioRhyme', serif`,
  bodyWeight: '400',
  textColor,
  linkColor: textColor,
  linkHover: textColor,
  header: {
    ...base.header,
    fontWeight: '800',
    lineHeight: '130%',
  },
}
export default Montserrat
