import Helmet from 'react-helmet'
import React from 'react'
import { generateLDJSON } from '../../utils/seo'

const LdJSON = ({ url, title, image, isPost, date, author }) => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify(
        generateLDJSON({ date, url, title, image, isPost, author })
      )}
    </script>
  </Helmet>
)

export default LdJSON
