import 'typeface-fira-mono'

const base = {
  textColor: 'black',
  linkColor: 'black',
  linkHover: 'black',
  backgroundColor: 'white',
  excerptText: 'black',
  header: {
    backgroundColor: 'black',
    color: 'white',
    link: {
      color: 'white',
    },
    fontWeight: '500',
  },
  maxwidth: '46rem',
  // tagsColor: '#f0204f',
  tagsColor: 'black',
  metadataColor: '#4545ca',
}

export default base
