import { css, createGlobalStyle, ThemeProvider } from 'styled-components'
import React from 'react'
import theme from '../themes'
import Media from './Media'

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  * {
    margin: 0;
  }
  body {
    font-family: ${({ theme }) => theme.bodyFont};
    font-weight: ${({ theme }) => theme.bodyWeight};
    font-size: ${({ theme }) => theme.fontSize};
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.backgroundColor};
  }
 
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: ${({ theme }) => theme.header.lineHeight};
    font-family: ${({ theme }) => theme.headerFont};
    font-weight: ${({ theme }) => theme.header.fontWeight};
  }

  h1 {
    font-family: ${({ theme: { headerFont, headlineFont } }) =>
      headlineFont || headerFont};
  }

  h2 {
    font-family: ${({ theme: { headerFont, headlineFont } }) =>
      headerFont || headlineFont};
  }

  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme: { subheaderFont, headerFont, headlineFont } }) =>
      subheaderFont || headlineFont || headerFont};
    font-weight: 600;
  }

  a {
    color: ${({ theme }) => theme.linkColor};
    text-decoration: none;

    :hover {
      text-decoration: none;
      color: ${({ theme }) => theme.linkHover};
    }
  }

  .icon-link {
    border-bottom: none;
    :hover {
      border-bottom: none;
      opacity: 0.5;
    }
    transition: opacity 0.5s ease-out;
  }

  .anchor {
    border-bottom: none;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0;
  }


  code {
    font-size: 0.9rem;
    font-family: 'Fira Mono', monospace;
    line-height: 150%;
    font-size: 90%;

    ${Media.tablet`
      font-size: 90%;
      letter-spacing: -0.5px;
    `}

    ${Media.phone`
      font-size: 80%;
      letter-spacing: -0.7px;
    `}
    :not(.vscode-highlight-code) {
      background: #ededed;
      letter-spacing: 0.025rem;
      padding: 0.03rem 0.2rem;
      font-size: 1rem;
      color: #891717;
      font-weight: 400;
    }
  }

  pre.vscode-highlight {
    margin-bottom: 2.5rem;
  }
`

export const FancyLinks = css`
  a:not(.anchor) {
    text-decoration: none;
    border-bottom: 2px dotted rgb(107, 161, 255);
    background: rgb(107, 161, 255, 0.09);
    transition: 0.3s ease all;

    :hover {
      text-decoration: none;
      border-bottom: 2px solid;
      background: rgb(107, 161, 255, 0.25);
    }
  }
`

export const SectionWithEnd = css`
  border-bottom: 1px solid #dbdbdb;
`

const RootStyled = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      {children}
      <GlobalStyle />
    </>
  </ThemeProvider>
)

export default RootStyled
