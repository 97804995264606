import React from 'react'
import styled from 'styled-components'

import {
  FaTwitter as TwitterIcon,
  FaLinkedin as TiLinkedIn,
  FaGithub as GithubIcon,
  FaGitlab as GitlabIcon,
  FaStackOverflow as TiStackOverflow,
} from 'react-icons/fa'

import { IconContext } from 'react-icons'

const SocialContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding-right: 1rem;
`

const SocialLink = styled.a`
  border: none;
`

const SocialIcons = ({
  size,
  twitter,
  linkedIn,
  stackOverflow,
  github,
  gitlab,
}) => (
  <IconContext.Provider value={{ style: { width: size, height: size } }}>
    <SocialContainer>
      <SocialLink className="icon-link" href={`https://twitter.com/${twitter}`}>
        <TwitterIcon color={'#2aa2ed'} title="Twitter" />
      </SocialLink>
      <SocialLink
        className="icon-link"
        href={`https://www.linkedin.com/in/${linkedIn}/`}
      >
        <TiLinkedIn color={'#0077b5'} title="LinkedIn" />
      </SocialLink>
      <SocialLink
        className="icon-link"
        href={`https://stackoverflow.com/story/${stackOverflow}`}
      >
        <TiStackOverflow color={'#000000'} title="StackOverflow" />
      </SocialLink>
      <SocialLink className="icon-link" href={`https://github.com/${github}`}>
        <GithubIcon color={'#000000'} title="Github" />
      </SocialLink>
      <SocialLink className="icon-link" href={`https://gitlab.com/${gitlab}`}>
        <GitlabIcon color={'#e24329'} title="GitLab" />
      </SocialLink>
    </SocialContainer>
  </IconContext.Provider>
)

export default SocialIcons
