export const Website = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: undefined,
  name: undefined,
  alernateName: undefined,
}

export const BreadcrumbList = {
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': undefined,
        name: undefined,
        image: undefined,
      },
    },
  ],
}
