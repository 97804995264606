import { Website } from './ld_json'

export const generateLDJSON = ({
  date,
  isPost,
  url,
  title,
  image,
  author: { name, email } = {},
}) => {
  return [
    {
      ...Website,
      url,
      name: title,
      alternateName: title,
      ...(image && { image }),
    },
    ...(isPost
      ? [
          {
            '@context': 'http://schema.org',
            '@type': 'BlogPosting',
            url,
            ...(image && { image }),
            headline: title,
            alternativeHeadline: title,
            datePublished: date,
            dateCreated: date,
            dateModified: date,
            author: {
              '@context': 'http://schema.org',
              '@type': 'Person',
              email,
              name,
            },
            publisher: {
              '@context': 'http://schema.org',
              '@type': 'Organization',
              email,
              name,
              logo: {
                '@type': 'ImageObject',
                url: image,
              },
            },
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': url,
            },
          },
        ]
      : []),
  ]
}
