import Helmet from 'react-helmet'
import React from 'react'

const OpenGraph = ({ isPost, title, url, description, image, siteName }) => (
  <Helmet>
    <meta property="og:site_name" content={siteName} />
    <meta property="og:url" content={url} />
    <meta property="og:type" content={isPost ? 'article' : 'website'} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    {image && <meta property="og:image" content={image} />}
  </Helmet>
)

export default OpenGraph
